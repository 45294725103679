body {
  margin: 0;
  padding: 0;
}

.new-item-date-calendar {
  position: absolute;
  margin-top: var(--hcr-spacing-2);
  width: 300px;
}

.theme-switcher-wrapper {
  height: 100%;
  display: grid;
  align-content: center;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.full-width {
  width: 100%;
}

.max400 {
  max-width: 400px;
}

.max500 {
  max-width: 500px;
}

.max600 {
  max-width: 600px;
}


.hcr-img-box__img-wrapper-7-7-0 {
  display: inline-table;
}