@import '@flixbus/honeycomb-react/dist/scss/honeycomb-helpers';

.language-switcher-wrapper {
  padding: cssvar(spacing-half);
  display: flex;
  align-items: center;
}

.language-switcher {
  .language-switcher__button {
    display: flex;
    font-size: 16px;
    font-family: 'Roboto Condensed', serif;
    color: cssvar(content-primary-color);
    background: none;
    border: 0;
    cursor: pointer;
  }

  .flag {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: cssvar(spacing-2);
    box-shadow: 0 0 3px 1px cssvar(input-border-color);
    background-repeat: no-repeat;
    background-size: cover;

    &--en {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/gb.min.svg');
    }
    &--de {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/de.min.svg');
    }
    &--it {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/it.min.svg');
    }
    &--hr {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/hr.min.svg');
    }
    &--cs {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/cz.min.svg');
    }
    &--pl {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/pl.min.svg');
    }
    &--tr {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/tr.min.svg');
    }
    &--fr {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/fr.min.svg');
    }
    &--es {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/es.min.svg');
    }
    &--es-cl {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/cl.min.svg');
    }
    &--pt {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/pt.min.svg');
    }
    &--pt-br {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/br.min.svg');
    }
    &--sv {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/se.min.svg');
    }
    &--da {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/dk.min.svg');
    }
    &--ro {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/ro.min.svg');
    }
    &--nl {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/nl.min.svg');
    }
    &--uk {
      background-image: url('https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/ua.min.svg');
    }
  }
  .lang-name {
    vertical-align: middle;
    margin-left: cssvar(spacing-2);
  }
}
